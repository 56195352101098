import React from "react";

const Footer = () => {
    return (
        <div className={'footer'}>
            <div className={'footer__wrapper'} />
            <div className={'footer__fake-block'} />
        </div>
    )
}
export default Footer;
