import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Rating } from "primereact/rating";
import { Button } from "primereact/button";
import People from "../images/block4_ico1.svg";
import { useHistory } from "react-router";
import { ProgressSpinner } from "primereact/progressspinner";
import ExternalReview from "components/common/ExternalReview";
import Agreement from "components/common/Agreement";
import Footer from "components/common/Footer";
import { mainService } from "services";
import Nps from "components/common/Nps";
import {getParam} from "./helpers";
import Header from "./common/Header";
import '../styles/main.scss';
import '../styles/templates.scss';

const CommonForm = () => {

    const utm = getParam();

    let history = useHistory();

    const [comment, setComment] = useState('');
    const [rating, setRating] = useState(0);
    const [nps, setNps] = useState(0);
    const [showDest, setShowDest] = useState(false);
    const [agreement, setAgreement] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [adplatforms, setAdplatforms] = useState([]);
    const [branch, setBranch] = useState('');
    const {npsOptions, destOptions, prepareHeader} = mainService;
    const [backErrors, setBackErrors] = useState([]);
    const [errors, setErrors] = useState({
        comment: false,
        rating: false,
        agreement: false,
    });

    useEffect(() => {
        document.querySelector('meta[property="og:title"]').content = 'Оставьте отзыв о компании'
        document.querySelector('meta[property="og:description"]').content = 'Оставьте отзыв о компании'
        document.title = 'Оставьте отзыв о компании'

    }, []);

    useEffect(() => {
        //TODO применить api.getClientInfo

        fetch(`${process.env.REACT_APP_API_URL}/review/${utm}/client-info`, { method: 'GET' })
            .then(response => response.json())
            .then(result => {
                result.data && setBranch(result.data.branch)
            });
    }, [utm]);

    useEffect(() => {
        setIsValid((
            !!comment.length &&
            !!rating &&
            !!agreement
        ));
    }, [comment, rating, agreement]);


    const validateForm = () => {
        setErrors({
            comment: !comment.trim().length,
            rating: !rating,
            agreement: !agreement,
        });
        return isValid;
    }

    const onSubmit = () => {
        if(!validateForm()) return false;

        setLoading(true);

        fetch(`${process.env.REACT_APP_API_URL}/review`,
            prepareHeader({
            "rating": rating,
            "nps_score": nps,
            "comment": comment,
            "publication": "Анонимно",
            "pd_agreement": "yes",
            "utm_term": utm,
        }))
            .then(response => response.json())
            .then(result => {
                if (result.statusCode === 200) {
                    setBackErrors([]);
                    fetch(`${process.env.REACT_APP_API_URL}/review/${utm}`, { method: 'GET' })
                        .then(response => response.json())
                        .then(result => {
                            let tmp = [];
                            // eslint-disable-next-line array-callback-return

                            if (result.data) {
                                const comment = `${result.data.text} ${result.data.customer.firstname[0].toUpperCase()}. ${result.data.customer.lastname[0].toUpperCase()}.`
                                setComment(comment);

                                result.data.adplatforms.forEach(item => {
                                    const tmp2 = destOptions.find((dest) => dest.platform === item.type);
                                    if (typeof tmp2 !== 'undefined') {
                                        tmp.push({
                                            ...tmp2,
                                            url: item.url
                                        })
                                    }
                                });

                                tmp.sort((a, b) => a.sort > b.sort ? 1 : -1);

                                setAdplatforms(tmp);
                            }

                            setLoading(false);
                            if (rating > 3) {
                                setShowDest(true);
                            } else {
                                history.push(`/claim_accepted`)
                            }
                        })
                }

                if (result.statusCode === 422 && !!result.error.data.length) {
                    let errorArray = []
                    result.error.data.map((error) => errorArray.push(error.message));
                    setBackErrors(errorArray);
                }
            })
            .catch(error => console.log('error', error))
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <>
            <Header/>
            <div className={cn('container', 'common')}>
                <div className={cn('from')}>
                    <div className={cn('form__header')}>
                        <h1 className={cn('header', 'header__text')}>Благодарим за выбор<br />{branch}{branch && '!'}</h1>
                        <div className={'header__subtext'}>
                            Ваши впечатления крайне важны для сотрудников компании и могут помочь другим клиентам
                            сделать правильный выбор
                        </div>
                    </div>
                    <div className={cn('form__container')}>
                        {loading && <div className={cn('loader')}><ProgressSpinner /></div>}
                        <div className={'element__wrapper'}>
                            <h3>Оцените ваши впечатления и качество обслуживания</h3>
                            <div>
                                <Rating
                                    className={'rating'}
                                    value={rating}
                                    cancel={false}
                                    onChange={(e) => setRating(e.value)}
                                    disabled={showDest}
                                />
                            </div>
                            {errors.rating && <div className={cn('errors__wrapper')}>Поставьте вашу оценку</div>}
                        </div>
                        <div className={'element__wrapper'}>
                            <h3>Что понравилось, а что можно улучшить?</h3>
                            <div className={cn('textarea__wrapper', {'error' : errors.comment})}>
                                <textarea
                                    name="message"
                                    id="message"
                                    value={comment}
                                    placeholder='Напишите ваш отзыв...'
                                    disabled={showDest}
                                    onChange={(e) => setComment(e.target.value)}
                                />
                            </div>
                            {errors.comment && <div className={cn('errors__wrapper')}>Поле обязательно для заполнения</div>}
                            {!!backErrors.length && <div className={cn('errors__wrapper')}>
                                {backErrors.map((error, key) => (<div key={key}>{error}</div>))}
                            </div>}
                        </div>

                        <div className={'element__wrapper'}>
                            <h3>Какова вероятность, что Вы порекомендуете {branch} своим друзьям и
                                знакомым?</h3>
                            <Nps
                                nps={nps}
                                npsOptions={npsOptions}
                                onChange={setNps}
                                disabled={showDest}
                            />
                        </div>

                        {showDest && <div className={'external-review__wrapper'}>
                            <div className={cn('external-review')}>
                                <h3>Будем признательны, если Вы разместите этот отзыв на одной из площадок</h3>
                                <div className={'external-review__subtext'}>Выберите площадку, чтобы перейти на нее. Ваш
                                    отзыв уже скопирован - просто вставьте его в поле для отзыва на площадке.
                                </div>
                                {adplatforms && <ExternalReview
                                    utm={utm}
                                    comment={comment}
                                    adplatforms={adplatforms} />
                                }
                            </div>
                        </div>}

                        {!showDest && <>
                            <div className={'button__wrapper'}>
                                <Button
                                    label="Оставить отзыв →"
                                    onClick={onSubmit}
                                />
                            </div>
                            <div className={cn('agreement')}>
                                <Agreement
                                    setAgreement={setAgreement}
                                    agreement={agreement}
                                    error={errors.agreement}
                                />
                            </div>
                        </>}
                    </div>

                    <div className={cn('img__wrapper')}>
                        <img src={People} alt="" className={cn('img-people', 'common')} />
                    </div>
                </div>
            </div>
            <>
                <Footer/>
            </>
        </>
    );
}

export default CommonForm;
