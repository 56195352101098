import React, { useEffect, useState } from "react";
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import MainForm from "./components/Main";
import SecondForm from "./components/Second";
import ThirdForm from "./components/Third";
import Thanks from "./components/Thanks";
import CommonForm from "./components/Common";
import NoTextReminder from './components/NoTextReminder';

import { Switch, Route } from "react-router-dom";
import YandexMetrika from "./components/YandexMetrika";
import Qr from "./components/Qr";
import ClaimAccepted from "./components/ClaimAccepted";
import FeedbackRequest from "./components/FeedbackRequest";
import { useLocation } from "react-router";
import cn from "classnames";

import "./styles/templates.scss";

function App() {

    const location = useLocation();
    const [pageStyleName, setPageStyleName] =  useState('');

    useEffect(() => {
        //TODO убрать в конфиг
        if(location.pathname.includes('ao_medicina')) {
            setPageStyleName('ao_medicina')
        }
    }, []);

    return (
        <>
            {<YandexMetrika/>}
            <div className={cn('page', pageStyleName)}>
                <Switch>
                    <Route path="/thanks" exact>
                        <Thanks/>
                    </Route>

                    <Route path="/claim_accepted">
                        <ClaimAccepted/>
                    </Route>

                    <Route path="/qr/:utm">
                        <Qr/>
                    </Route>

                    <Route path="/common">
                        <CommonForm/>
                    </Route>

                    <Route path="/notextreminder">
                        <NoTextReminder/>
                    </Route>

                    <Route path="/second">
                        <SecondForm/>
                    </Route>

                    <Route path="/feedback_request">
                        <FeedbackRequest/>
                    </Route>

                    <Route path="/third">
                        <ThirdForm/>
                    </Route>

                    <Route path="/new">
                        <MainForm/>
                    </Route>

{/*TODO настройки шаблонов убрать во внешний компонент*/}
                    <Route path="/ao_medicina/claim_accepted" exact>
                        <Thanks template={'ao_medicina'}/>
                    </Route>
                    <Route path="/ao_medicina/second/:utm" exact>
                        <SecondForm template={'ao_medicina'}/>
                    </Route>
                    <Route path="/ao_medicina/qr/:utm" exact>
                        <Qr template={'ao_medicina'}/>
                    </Route>
                    <Route path="/ao_medicina/" exact>
                        <MainForm template={'ao_medicina'}/>
                    </Route>

                    <Route path="/ao_medicina/notextreminder">
                        <NoTextReminder template={'ao_medicina'}/>
                    </Route>


                </Switch>

            </div>
        </>
    );
}

export default App;
