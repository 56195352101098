import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Rating } from "primereact/rating";
import { Button } from "primereact/button";
import Salon from "../images/block4_ico2.svg";
import People from "../images/block4_ico1.svg";
import { useHistory } from "react-router";
import { ProgressSpinner } from "primereact/progressspinner";
import { mainService } from "services";
import Agreement from "components/common/Agreement";
import Footer from "components/common/Footer";
import ExternalReview from "components/common/ExternalReview";
import {getParam} from "./helpers";
import Header from "./common/Header";
import '../styles/main.scss';
import '../styles/templates.scss';

const ThirdForm = () => {

    const utm = getParam();
    let history = useHistory();

    const [comment, setComment] = useState('');
    const [rating, setRating] = useState(0);
    const [agreement, setAgreement] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [adplatforms, setAdplatforms] = useState([]);

    const { destOptions, prepareHeader } = mainService;

    useEffect(() => {
        setIsValid((
            !!comment.length &&
            !!agreement
        ));
    }, [comment, agreement]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/review/${utm}`, { method: 'GET' })
            .then(response => response.json())
            .then(result => {
                let tmp = [];
                // eslint-disable-next-line array-callback-return
                if (result.data) {
                    result.data?.adplatforms.forEach(item => {
                        const tmp2 = destOptions.find((dest) => dest.platform === item.type);
                        if (typeof tmp2 !== 'undefined') {
                            tmp.push({
                                ...tmp2,
                                url: item.url
                            })
                        }
                    });

                    tmp.sort((a, b) => a.sort > b.sort ? 1 : -1);

                    setAdplatforms(tmp);
                }
                setLoading(false);
            })
// eslint-disable-next-line
    }, []);

    const onSubmit = () => {
        setLoading(true);

        fetch(`${process.env.REACT_APP_API_URL}/review`,
            prepareHeader({
                "rating": rating,
                "comment": comment,
                "publication": "Анонимно",
                "pd_agreement": "yes",
                "utm_term": utm,
            }))
            .then(response => response.json())
            .then(result => {
                if(result.statusCode === 200) {
                    history.push(`/thanks`)
                }
                if (result.statusCode === 422 && !!result.error.data.length) {
                    let errorArray = []
                    result.error.data.map((error) => errorArray.push(error.message));
                    setErrors(errorArray);
                }
            })
            .finally(() => {
                setLoading(false);
            });

    }

    return (
        <>
            <Header/>
            <div className={cn('container', 'thrid')}>
                <div className={cn('from')}>
                    <div className={cn('form__header')}>
                        <h1 className={cn('header', 'header__text')}>Поздравляем с покупкой!</h1>
                        <div className={'header__subtext'}>
                            Ваши впечатления крайне важны для нас и могут помочь другим клиентам сделать правильный выбор!
                        </div>
                    </div>
                    <div className={cn('form__container')}>
                        {loading && <div className={cn('loader')}><ProgressSpinner /></div>}
                        <div className={'element__wrapper_fake'}>
                            <h3>Оцените впечатление от покупки и обслуживания</h3>
                            <div>
                                <Rating
                                    className={'rating'}
                                    value={rating}
                                    cancel={false}
                                    onChange={(e) => setRating(e.value)}
                                />
                            </div>
                        </div>
                        {/* если  рейтинг 4 или 5, то просим перейти на площадку и там оставить отзыв */}
                        {(rating === 5 || rating === 4) && <div className={'external-review__wrapper'}>
                            <div className={'spacer'} />
                            <div className={cn('external-review')}>
                                <h3>Будем признательны, если Вы оставите отзыв на одном из сайтов:</h3>
                                <div className={'external-review__subtext'}>Выберите площадку, чтобы перейти на нее.
                                </div>
                                {adplatforms && <ExternalReview
                                    utm={utm}
                                    comment={comment}
                                    adplatforms={adplatforms} />
                                }
                            </div>
                        </div>}

                        {/* если все не рейтинг ниже 4, топросим оставить отзыв */}
                        {rating !== 0 && rating < 4 && <>
                            <div className={'element__wrapper'}>
                                <div className={'spacer'} />
                                <h3>Напишите, пожалуйста, что Вам не понравилось и что нужно сделать лучше</h3>
                                <div className={cn('textarea__wrapper')}>
                                <textarea
                                    name="message"
                                    id="message"
                                    value={comment}
                                    placeholder='Напишите ваш отзыв...'
                                    onChange={(e) => setComment(e.target.value)}
                                />
                                    {!!errors.length && <div className={cn('errors__wrapper')}>
                                        {errors.map((error, key) => (<div key={key}>{error}</div>))}
                                    </div>}
                                </div>
                            </div>

                            <div className={'button__wrapper'}>
                                <Button
                                    label="Оставить отзыв →"
                                    onClick={onSubmit}
                                    disabled={!isValid}
                                />
                            </div>
                            <div className={cn('agreement')}>
                                <Agreement
                                    setAgreement={setAgreement}
                                    agreement={agreement}
                                />
                            </div>
                        </>}
                    </div>

                    <div className={cn('img__wrapper')}>
                        <img src={Salon} alt="" className={'img-salon'} />
                        <img src={People} alt="" className={'img-people'} />
                    </div>
                </div>
            </div>
            <>
                <Footer/>
            </>
        </>
    );
}

export default ThirdForm;
